// Generated by Framer (d2b7042)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["nISib_8Bb", "Ry8ZgpxYV", "wf8p67DMn", "k2PID4rcY", "IYjwAz9gr", "oS68pkH1r", "KmbDFJTo_", "GYHYWcTnf", "jhNsHDzUv", "zXF7dQQFL", "qe7iYCqQ6"];

const serializationHash = "framer-oDTc4"

const variantClassNames = {GYHYWcTnf: "framer-v-c4f4qn", IYjwAz9gr: "framer-v-5ybh3d", jhNsHDzUv: "framer-v-124tt73", k2PID4rcY: "framer-v-5ulpcj", KmbDFJTo_: "framer-v-18d9qit", nISib_8Bb: "framer-v-y047qt", oS68pkH1r: "framer-v-1pz2hwp", qe7iYCqQ6: "framer-v-1s8ck8i", Ry8ZgpxYV: "framer-v-1kj8r1u", wf8p67DMn: "framer-v-1j23q1x", zXF7dQQFL: "framer-v-1muttsa"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}, k2PID4rcY: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Image 1:1": "oS68pkH1r", "Image 16:9": "k2PID4rcY", "Image 3:2": "wf8p67DMn", "Image 4:3": "Ry8ZgpxYV", "Image 4:5": "IYjwAz9gr", "Variant 10": "zXF7dQQFL", "Variant 11": "qe7iYCqQ6", "Variant 7": "KmbDFJTo_", "Variant 8": "GYHYWcTnf", "Variant 9": "jhNsHDzUv", Default: "nISib_8Bb"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "nISib_8Bb", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "nISib_8Bb", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", sizes: "min(256px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-y047qt", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"nISib_8Bb"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({GYHYWcTnf: {"data-framer-name": "Variant 8"}, IYjwAz9gr: {"data-framer-name": "Image 4:5"}, jhNsHDzUv: {"data-framer-name": "Variant 9"}, k2PID4rcY: {"data-framer-name": "Image 16:9"}, KmbDFJTo_: {"data-framer-name": "Variant 7"}, oS68pkH1r: {"data-framer-name": "Image 1:1"}, qe7iYCqQ6: {"data-framer-name": "Variant 11"}, Ry8ZgpxYV: {"data-framer-name": "Image 4:3"}, wf8p67DMn: {"data-framer-name": "Image 3:2"}, zXF7dQQFL: {"data-framer-name": "Variant 10"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-oDTc4[data-border=\"true\"]::after, .framer-oDTc4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-oDTc4.framer-1dtgej8, .framer-oDTc4 .framer-1dtgej8 { display: block; }", ".framer-oDTc4.framer-y047qt { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-oDTc4.framer-v-1kj8r1u.framer-y047qt { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-oDTc4.framer-v-1j23q1x.framer-y047qt { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-oDTc4.framer-v-5ulpcj.framer-y047qt { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-oDTc4.framer-v-5ybh3d.framer-y047qt, .framer-oDTc4.framer-v-1muttsa.framer-y047qt, .framer-oDTc4.framer-v-1s8ck8i.framer-y047qt { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-oDTc4.framer-v-1pz2hwp.framer-y047qt { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }", ".framer-oDTc4.framer-v-18d9qit.framer-y047qt { aspect-ratio: 2.56 / 1; height: var(--framer-aspect-ratio-supported, 100px); }", ".framer-oDTc4.framer-v-c4f4qn.framer-y047qt { aspect-ratio: 0.46545454545454545 / 1; height: var(--framer-aspect-ratio-supported, 550px); }", ".framer-oDTc4.framer-v-124tt73.framer-y047qt { aspect-ratio: 0.5688888888888889 / 1; height: var(--framer-aspect-ratio-supported, 450px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 256
 * @framerIntrinsicWidth 256
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Ry8ZgpxYV":{"layout":["fixed","fixed"]},"wf8p67DMn":{"layout":["fixed","fixed"]},"k2PID4rcY":{"layout":["fixed","fixed"]},"IYjwAz9gr":{"layout":["fixed","fixed"]},"oS68pkH1r":{"layout":["fixed","fixed"]},"KmbDFJTo_":{"layout":["fixed","fixed"]},"GYHYWcTnf":{"layout":["fixed","fixed"]},"jhNsHDzUv":{"layout":["fixed","fixed"]},"zXF7dQQFL":{"layout":["fixed","fixed"]},"qe7iYCqQ6":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framern3Kcf2rQj: React.ComponentType<Props> = withCSS(Component, css, "framer-oDTc4") as typeof Component;
export default Framern3Kcf2rQj;

Framern3Kcf2rQj.displayName = "Image / Aspect Ratio Copy 6";

Framern3Kcf2rQj.defaultProps = {height: 256, width: 256};

addPropertyControls(Framern3Kcf2rQj, {variant: {options: ["nISib_8Bb", "Ry8ZgpxYV", "wf8p67DMn", "k2PID4rcY", "IYjwAz9gr", "oS68pkH1r", "KmbDFJTo_", "GYHYWcTnf", "jhNsHDzUv", "zXF7dQQFL", "qe7iYCqQ6"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1", "Variant 7", "Variant 8", "Variant 9", "Variant 10", "Variant 11"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(Framern3Kcf2rQj, [])